import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.svg";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.svg";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.eot";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.eot";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.ttf";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.ttf";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.woff";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.woff";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Bold.woff2";
import "./assets/fonts/FontinsSansSR/subset-FontinSansCR-Regular.woff2";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
